<template>
<div class="mainform locationManage">
    <div class="form">
        <el-form ref="form" class="content" :model="form" label-width="170px" :disabled="false">
          <div class="col col8">
            <el-form-item label="库位名称" prop="entrPosiName" :rules="{ required: true, message: '请输入库位名称', trigger: 'blur' }">
                <el-input v-model="form.entrPosiName"></el-input>
            </el-form-item>
          </div>
          <div class="col col8">
            <el-form-item label="库位类型" prop="entrPosiType" :rules="{ required: true, message: '请选择库位类型', trigger: 'blur' }">
                <el-select filterable v-model="form.entrPosiType" placeholder="请选择">
                  <el-option label="外协库" :value='1'></el-option>
                  <el-option label="返修库" :value='2'></el-option>
                  <el-option label="工厂库" :value='3'></el-option>
                  <el-option label="vmi库" :value='4'></el-option>
                </el-select>
            </el-form-item>
          </div>
          <div class="col col8">
            <el-form-item label="地址" prop="entrPosiAddr" :rules="{ required: true, message: '请输入地址', trigger: 'blur' }">
                <el-input v-model="form.entrPosiAddr">
                  <template #append>
                    <el-button type="primary" icon="el-icon-plus" @click="selectAddr"></el-button>
                  </template>
                </el-input>
            </el-form-item>
          </div>
          <div class="col col8">
            <el-form-item label="联系人">
                <el-input v-model="form.entrPosiHead">
                  <template #append>
                    <el-button type="primary" icon="el-icon-plus" @click="selectCont"></el-button>
                  </template>
                </el-input>
            </el-form-item>
          </div>
          <div class="col col8">
            <el-form-item label="联系方式">
                <el-input v-model="form.entrPosiHeadPhone"></el-input>
            </el-form-item>
          </div>
        </el-form>
    </div>
    <dialog-box ref="dialogbox" dialogWidth="50%" :dialogShow="dialogShow" componentName="GlobalTable"
                @handleClose='handleClose' title="选择地址" :tableField="tableField" :tableData="tableData"
        :paginationData="paginationData" highlight-current-row @current-change="handleCurrentChange"
        @pageChange="pageChange"
        :buttonData="buttonData" @action="action" ></dialog-box>
        <dialog-box ref="dialogbox" dialogWidth="50%" :dialogShow="dialogShow1" componentName="GlobalTable"
                @handleClose='handleClose' title="选择联系人" :tableField="tableField1" :tableData="tableData1"
        :paginationData="paginationData" highlight-current-row @current-change="handleCurrentChange1"
        @pageChange="pageChange1"
        :buttonData="buttonData" @action="action1" ></dialog-box>
</div>
</template>

<script>
import Component from '@/assets/js/components.js'
import { addrTableField, ContTableField } from './js/setting.js'
import { request } from '@/assets/js/http.js'
export default {
  name: 'AddLocation',
  components: Component.components,
  props: ['formData'],
  data: function () {
    return {
      form: this.formData,
      dialogShow: false,
      dialogShow1: false,
      tempData: '',
      tempDataPsn: '',
      tempDataPhone: '',
      tableField: addrTableField,
      tableField1: ContTableField,
      tableData: [],
      tableData1: [],
      paginationData: { pageSize: 15, total: 1 },
      buttonData: [{ label: '取消', action: 'handleClose', size: 'mini' }, { label: '确认', action: 'action', size: 'mini', type: 'primary' }]
    }
  },
  methods: {
    selectAddr () {
      request('/api/supplier/basicData/addressManagement', 'get', { pageNum: 1, pageSize: 15 }).then((Response) => {
        if (Response.code === '200') {
          this.tableData = Response.data.records
          this.paginationData = { pageSize: 15, total: Response.data.total }
          this.dialogShow = true
          this.tempData = this.form.entrPosiAddr
        }
      })
    },
    selectCont () {
      request('/api/supplier/basicData/contactManagement', 'get', { pageNum: 1, pageSize: 15 }).then((Response) => {
        if (Response.code === '200') {
          this.tableData1 = Response.data.records
          this.paginationData = { pageSize: 15, total: Response.data.total }
          this.dialogShow1 = true
          this.tempDataPsn = this.form.entrPosiHead
          this.tempDataPhone = this.form.entrPosiHeadPhone
        }
      })
    },
    action () {
      this.form.entrPosiAddr = this.tempData
      this.dialogShow = false
    },
    action1 () {
      this.form.entrPosiHead = this.tempDataPsn
      this.form.entrPosiHeadPhone = this.tempDataPhone
      this.dialogShow1 = false
    },
    pageChange (data) {
      const searchData = {
        pageNum: data,
        pageSize: 15
      }
      request('/api/supplier/basicData/addressManagement', 'get', searchData).then((Response) => {
        if (Response.code === '200') {
          this.tableData = Response.data.records
          if (this.paginationData.total !== Response.data.total) {
            this.paginationData.total = Response.data.total
          }
        }
      })
    },
    pageChange1 (data) {
      const searchData = {
        pageNum: data,
        pageSize: 15
      }
      request('/api/supplier/basicData/contactManagement', 'get', searchData).then((Response) => {
        if (Response.code === '200') {
          this.tableData1 = Response.data.records
          if (this.paginationData.total !== Response.data.total) {
            this.paginationData.total = Response.data.total
          }
        }
      })
    },
    handleCurrentChange (data) {
      this.tempData = data.province + data.city + data.area + data.detailedAddress
    },
    handleCurrentChange1 (data) {
      this.tempDataPsn = data.name
      this.tempDataPhone = data.phone
    },
    handleClose () {
      this.dialogShow = false
      this.dialogShow1 = false
    },
    changAction: function (data) {
      if (data.country) {
        this.form.country = data.country
      }
      if (data.province) {
        this.form.province = data.province
      }
      if (data.city) {
        this.form.city = data.city
      }
      if (data.district) {
        this.form.area = data.district
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import '@/assets/css/elForm.scss';
  .mainform{
    background: none;
    padding: 0;
    .form{
      background: none;
      .content{
        padding: 0px 0;
      }
    }
  }
  .locationManage{
    :deep(.dialogBox){
      .el-pagination{
        *{
          background: none;
        }
      }
    }
  }
.mainform .form .el-form .el-form-item:last-child {
    margin-bottom: 0px;
}
</style>
